import { ComponentType, useContext, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import { Collapse, Stack } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import _isString from 'lodash/isString'

import {
	Box,
	Button,
	Checkbox,
	Divider,
	Grid,
	Modal,
	Select,
	Text,
	TextField,
} from '../../../components'
import { TableContext } from '../../../providers/table-provider'
import { colors } from '../../../theme/colors'
import { PlusIcon, TrashIcon } from '../../../assets/icons'

import { BasicQuestionaireData, CangoQuestionaireNode } from './types'
import { ListLetter, numberToLetter } from './list-letter'

type NodeModalProps = {
	allNodes: CangoQuestionaireNode<BasicQuestionaireData>[]
	node: TableTypes.Record
	questionColumnId: string
	optionsColumnId: string
	onClose: () => void
	isGlobalQuestion: boolean
}

type DescendantConditionForm = {
	descendants: TableTypes.Descendant[]
}

const OptionConditionValueInput: ComponentType<{
	fromQuestion: BasicQuestionaireData
	index: number
}> = ({ fromQuestion, index }) => {
	const { control } = useFormContext<DescendantConditionForm>()

	if (fromQuestion.answerConfig.options.length) {
		return (
			<Controller
				control={control}
				name={`descendants.${index}.option_condition.values`}
				render={({ field: { value, onChange } }) => (
					<Select
						options={fromQuestion.answerConfig.options.map((_option, index) => ({
							_id: _option._id,
							label: (
								<Box>
									<Stack direction="row" spacing={1} alignItems="center">
										<ListLetter alphabetIndex={index + 1} />
										<Text>{_option.label}</Text>
									</Stack>
								</Box>
							),
						}))}
						renderValue={(selected) => {
							if (_isString(selected)) {
								selected = [selected]
							}
							return (selected as string[])
								.map((_id) => {
									const listItemIndex = fromQuestion.answerConfig.options.findIndex(
										(_option) => _option._id === _id,
									)
									if (listItemIndex === -1) {
										return
									}
									const listItemLabel = fromQuestion.answerConfig.options[listItemIndex]?.label
									if (!listItemLabel) {
										return numberToLetter(listItemIndex + 1)
									}
									return listItemLabel
								})
								.filter(Boolean)
								.join(', ')
						}}
						value={_isString(value) ? [] : (value ?? [])}
						onChange={onChange}
						fullWidth
						multiple
						disableOrdering
					/>
				)}
			/>
		)
	}

	return (
		<Controller
			control={control}
			name={`descendants.${index}.option_condition.values.0`}
			render={({ field: { value, onChange } }) => (
				<TextField value={value} onChange={onChange} fullWidth />
			)}
		/>
	)
}

const ConditionField: ComponentType<{
	selectedNode: TableTypes.Record
	index: number
	allNodes: CangoQuestionaireNode<BasicQuestionaireData>[]
	onAddRule: () => void
	onRemove?: () => void
}> = ({ index, allNodes, onAddRule, onRemove, selectedNode }) => {
	const { control, watch, setValue } = useFormContext<DescendantConditionForm>()
	const allConditions = watch('descendants')
	const condition = watch(`descendants.${index}`)

	const label = useMemo(() => {
		if (condition.option_condition) {
			return 'then go to'
		}

		if (allConditions.some((_cond) => _cond.option_condition)) {
			return 'All other cases go to'
		}

		return 'Always go to'
	}, [allConditions, index])

	const fromRow = useMemo(() => {
		if (!condition.option_condition?.from) {
			return
		}

		return allNodes.find((_node) => _node.id === condition.option_condition?.from)?.data
	}, [condition.option_condition?.from])

	const handleFromChange = (currentFromId: string, fromId: string) => {
		if (currentFromId === fromId) {
			return
		}
		const fromNode = allNodes.find((_node) => _node.id === fromId)
		if (!fromNode) {
			return
		}
		const fromIsString = [TableTypes.AnswerType.ShortText, TableTypes.AnswerType.LongText].includes(
			fromNode.data?.answerConfig?.answerType,
		)
		const conditionValues: string[] = []
		if (!fromIsString) {
			conditionValues.push('')
		}
		setValue(`descendants.${index}.option_condition`, {
			from: fromId,
			operator: TableTypes.ConditionOperator.Is,
			values: conditionValues,
		})
	}

	const { nodeToList, nodeFromList } = useMemo(() => {
		const indexOfSelectedNode = allNodes.findIndex((_node) => _node.id === selectedNode._id)
		return allNodes.reduce(
			(
				_acc: {
					nodeToList: { _id: string; label: string }[]
					nodeFromList: { _id: string; label: string }[]
				},
				_node,
				nodeIndex,
			) => {
				if (nodeIndex <= indexOfSelectedNode) {
					_acc.nodeFromList.push({
						_id: _node.id,
						label: _node.data.label,
					})
				} else {
					_acc.nodeToList.push({
						_id: _node.id,
						label: _node.data.label,
					})
				}
				return _acc
			},
			{
				nodeToList: [],
				nodeFromList: [],
			},
		)
	}, [allNodes])

	return (
		<Box mb={1}>
			<Grid container py={2} px={2} bgcolor={colors.neutral['20']} borderRadius="8px">
				{!!condition.option_condition && (
					<>
						<Grid xs={3} display="flex" alignItems="center">
							If
						</Grid>
						<Grid xs={9}>
							<Stack>
								<Controller
									control={control}
									name={`descendants.${index}.option_condition.from`}
									render={({ field: { value } }) => (
										<Select
											options={nodeFromList}
											value={value}
											onChange={(event) =>
												handleFromChange(value ?? '', event.target.value as string)
											}
										/>
									)}
								/>
								<Controller
									control={control}
									name={`descendants.${index}.option_condition.operator`}
									render={({ field: { value, onChange } }) => (
										<Select
											options={[
												{
													_id: TableTypes.ConditionOperator.Is,
													label: 'Is',
												},
												{
													_id: TableTypes.ConditionOperator.IsNot,
													label: 'Is not',
												},
												{
													_id: TableTypes.ConditionOperator.Any,
													label: 'Any',
												},
											]}
											value={value}
											onChange={onChange}
											containerProps={{ width: 100 }}
										/>
									)}
								/>
								{!!fromRow && <OptionConditionValueInput fromQuestion={fromRow} index={index} />}
							</Stack>
						</Grid>
						<Grid xs={12} py={2}>
							<Divider variant="fullWidth" />
						</Grid>
					</>
				)}
				<Grid xs={3} display="flex" alignItems="center">
					{label}
				</Grid>
				<Grid xs={9} display="flex" alignItems="center">
					<Stack>
						<Controller
							control={control}
							name={`descendants.${index}.row`}
							render={({ field: { value, onChange } }) => (
								<Select
									options={nodeToList}
									value={value}
									onChange={onChange}
									fullWidth
									containerProps={{ width: 400 }}
								/>
							)}
						/>
					</Stack>
				</Grid>
				<Button
					variant="text"
					startIcon={
						onRemove ? <TrashIcon width={20} stroke={colors.error.main} /> : <PlusIcon width={20} />
					}
					sx={{ minWidth: 75 }}
					color={onRemove ? 'error' : 'primary'}
					onClick={onRemove ?? onAddRule}
				>
					{onRemove ? 'Remove' : 'Add'} rule
				</Button>
			</Grid>
		</Box>
	)
}

export const NodeModal: ComponentType<NodeModalProps> = ({
	node,
	questionColumnId,
	onClose,
	allNodes,
	isGlobalQuestion,
}) => {
	const { updateDecendants, updateTableConfig, table, isUpdatingTable } = useContext(TableContext)
	const form = useForm<DescendantConditionForm>({
		defaultValues: {
			descendants: node.descendants ?? [],
		},
	})
	const { fields, insert, remove } = useFieldArray({ control: form.control, name: 'descendants' })
	const allDescendants = form.watch('descendants')

	const handleAddDescendant = () => {
		if (allDescendants.length > 0) {
			insert(0, {
				_id: v4(),
				row: '',
				option_condition: {
					from: node._id,
					operator: TableTypes.ConditionOperator.Is,
					values: [],
				},
			})
			return
		}
		insert(0, {
			_id: v4(),
			row: '',
		})
	}

	const handleChangeGlobalQuestion = async (isChecked: boolean) => {
		let globalQuestions = table?.global_questions ?? []
		if (isChecked) {
			globalQuestions.push(node._id)
		} else {
			globalQuestions = globalQuestions.filter((_id) => _id !== node._id)
		}
		await updateTableConfig({ global_questions: globalQuestions })
	}

	const handleSave = (data: DescendantConditionForm) => {
		const cleanedDescendants = data.descendants
			.map((_desc) => ({
				..._desc,
				option_condition: _desc.option_condition?.values.length
					? _desc.option_condition
					: undefined,
			}))
			.filter((_desc) => _desc.row)
		updateDecendants({ rowId: node._id, descendants: cleanedDescendants })
		onClose()
	}

	useEffect(() => {
		if (!fields.length) {
			handleAddDescendant()
		}
	}, [fields])

	return (
		<FormProvider {...form}>
			<Modal open={true} onClose={onClose}>
				<Box width={600} height={600}>
					<Text variant="h6">{(node.data[questionColumnId] as string) ?? ''}</Text>
					<Checkbox
						label="Global question"
						checked={isGlobalQuestion}
						onChange={(e, checked) => handleChangeGlobalQuestion(checked)}
						disabled={isUpdatingTable}
					/>
					<TransitionGroup>
						{fields.map((field, index) => (
							<Collapse key={field.id}>
								<ConditionField
									selectedNode={node}
									index={index}
									allNodes={allNodes}
									onRemove={index !== fields.length - 1 ? () => remove(index) : undefined}
									onAddRule={handleAddDescendant}
								/>
							</Collapse>
						))}
					</TransitionGroup>
					<Button onClick={form.handleSubmit(handleSave)}>Save</Button>
				</Box>
			</Modal>
		</FormProvider>
	)
}
