import React, { ComponentType, useContext, useState } from 'react'
import { Stack } from '@mui/material'

import { Drawer } from 'src/components'
import { TaskProvider } from 'src/providers/task-provider'
import { DriveFilesContext } from 'src/providers'

import { useParentFolderFiles } from '../../../../components/drive-upload/use-parent-folder-files'
import { invariantDefined } from '../../../../helpers/invariant'

import { DeleteTask } from './delete-task'
import ConfigureTask from './configure-task'

type TaskDrawerProps = {
	onClose: () => void
	parentId?: string
	onRevertInstance: (instanceId: string, sectionId: string) => Promise<void>
	taskId?: string
}

export type SectionAssignationType = {
	assigned: boolean
	assignee: string
	role: string
}

export const TaskDrawer: ComponentType<TaskDrawerProps> = ({
	onClose,
	parentId,
	onRevertInstance,
	taskId,
}) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [isUpdating, setIsUpdating] = useState(false)
	const { parentFolderName, parentFolderId } = useContext(DriveFilesContext)
	const { fetchFiles } = useParentFolderFiles(
		invariantDefined(parentFolderId, 'parentFolderId is required'),
		parentFolderName as string,
	)

	const handleCloseDrawer = () => {
		setShouldPromptDelete(false)
		onClose()
	}

	const content = () => {
		if (!taskId) {
			return null
		}

		if (shouldPromptDelete) {
			return <DeleteTask onCloseDrawer={handleCloseDrawer} />
		}

		return (
			<ConfigureTask
				onDelete={() => setShouldPromptDelete(true)}
				parentId={parentId}
				setIsUpdating={setIsUpdating}
				onRevertInstance={onRevertInstance}
			/>
		)
	}

	return (
		<Drawer open={!!taskId} onClose={handleCloseDrawer} isLoading={isUpdating}>
			<TaskProvider taskId={taskId} onCloseDrawer={onClose} fetchFiles={fetchFiles}>
				<Stack width={600} px={3} my={3} role="presentation">
					{content()}
				</Stack>
			</TaskProvider>
		</Drawer>
	)
}
